/*
 * @Author: fengyanjing
 * @Date: 2020-06-02 14:05:28
 * @Description:实力瘦公用的推荐奖详情
 * @FilePath: /frontend/src/pages/rebate/shilishou/common/recommend-bonus/index.js
 */
import CompRebateLayoutTwo from "@/components/rebate/comp-rebate-layout-two/index.vue";
import RebateTable from "@/components/rebate/rebate-table/index.vue";
import rebateMixin from "@/mixins/shilishou-rebate";
import { getRecommendBonus } from "@/service/rebate/shilishouRebate";
import Rmb from "@/components/common/rmb/index.vue";

export default {
  name: "recommend-bonus",
  mixins: [rebateMixin],
  components: {
    CompRebateLayoutTwo,
    RebateTable,
    Rmb,
  },
  data() {
    return {};
  },
  computed: {
    //  直接推荐展示的行名
    directColumns() {
      const { userInfo } = this;
      const columns = [
        {
          label: "名称",
          key: "name",
        },
        {
          label: "团购金额",
          isRmb: true,
          key: "recommendTotal",
        },
        {
          label: "推荐奖励",
          isRmb: true,
          key: "recommendReferral",
        },
      ];
      //不是战略
      if (userInfo.levelId !== "4") {
        columns[0].render = function render(value) {
          return `${value}团队`;
        };
      }

      return columns;
    },
    //  间接推荐展示的行名
    indirectColumns() {
      const { userInfo } = this;
      const columns = [
        {
          label: "名称",
          key: "name",
        },
        {
          label: "团购金额",
          isRmb: true,
          key: "indirectTotal",
        },
        {
          label: "推荐奖励",
          isRmb: true,
          key: "indirectReferral",
        },
      ];
      //不是战略
      if (userInfo.levelId !== "4") {
        columns[0].render = function render(value) {
          return `${value}团队`;
        };
      }

      return columns;
    },
    //  直接推荐奖
    directRecomendList() {
      if (this.rebateInfo) {
        return this.rebateInfo.recommendSolderList || [];
      }
      return [];
    },
    //  间接推荐奖
    indirectRecomendList() {
      if (this.rebateInfo) {
        return this.rebateInfo.indirectSolderList || [];
      }
      return [];
    },
    // 合伙人推荐奖励
    bonusSum() {
      if (this.rebateInfo) {
        return (
          Number(this.rebateInfo.recommendReferral) +
          Number(this.rebateInfo.indirectReferral)
        );
      }
      return "--";
    },
    sumTextObj() {
      const { userInfo } = this;
      let amountText = "";
      let bonusText = "";
      switch (userInfo.levelId) {
        case "1":
        case "2":
          amountText = "推荐团购金额";
          bonusText = "推荐奖励";
          break;
        case "3":
          amountText = "创始人推荐团购金额";
          bonusText = "创始人推荐奖励";
          break;
        case "4":
          amountText = "合伙人推荐团购金额";
          bonusText = "合伙人推荐奖励";
          break;
        default:
          break;
      }
      return {
        amountText,
        bonusText,
      };
    },
    // 合伙人推荐团购金额
    amountSum() {
      if (this.rebateInfo) {
        return (
          Number(this.rebateInfo.recommendCount) +
          Number(this.rebateInfo.indirectCount)
        );
      }
      return "--";
    },
  },
  methods: {
    //  初始化页面
    init() {
      const { startTime, endTime, agentId } = this;
      getRecommendBonus({
        startTime,
        endTime,
        userId: agentId,
      }).then((data) => {
        this.rebateInfo = data;
      });
    },
  },
};
